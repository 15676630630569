import React from "react";
import PropTypes from "prop-types";
import { Divider, Flex, Box, Image, Link, Text } from "@chakra-ui/react";

const AuthLayout = ({ children, footerContent, backgroundImageUrl }) => {
  return (
    <Flex height="100vh" direction="column">
      <Flex pl={["30px", "60px", null, "100px"]} pt="40px">
        <Link href="/">
          <Image htmlHeight="17px" w="100px" src="/logo.svg" />
        </Link>
      </Flex>

      <Flex
        flex="1"
        align="space-between"
        position="relative"
        direction={["column", null, "row-reverse"]}
      >
        <Flex
          position={["absolute", null, "initial"]}
          top="-30px"
          left="30%"
          width="70%"
          pl={["12", "12", "12", "90px"]}
          pb="100px"
          flex="5"
          align="flex-start"
          direction="column"
          justify="center"
        >
          <Image src={backgroundImageUrl} />
        </Flex>
        <Flex
          direction="column"
          minW={[null, "450px"]}
          flex="3"
          align={["center", null, "flex-end"]}
          justify="center"
          zIndex="1"
        >
          {/* This empty box component ensures the content is centered */}
          <Box flex={1} />
          <Box maxWidth="450px" w="100%" px="6">
            {children}
          </Box>
          {/* This empty box component ensures we have a sticky footer */}
          <Box flex={1} />
          {
            // Development build indication
            process.env.NEXT_PUBLIC_VERCEL_ENV !== "production" &&
            process.env.NEXT_PUBLIC_VERCEL_ENV !== "preview" ? (
              <Text color="red" ml={2} size={"xs"}>
                staging
              </Text>
            ) : null
          }
          <Divider w="100%" />
          <Flex
            align="center"
            direction={["column", "row"]}
            flexWrap="wrap"
            justify="space-between"
            w="100%"
            py="6"
            pl={["2", "4", null, null, "16"]}
            pr="2"
          >
            {footerContent}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  footerContent: PropTypes.node.isRequired,
  backgroundImageUrl: PropTypes.string.isRequired,
};

export default AuthLayout;
