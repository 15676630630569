import React, { useEffect, useState } from "react";
import { Flex, Link, Text, useTheme } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { postUnauthenticatedRoute, useAuth } from "../utils/auth";
import Auth from "../components/Auth";
import useAuthErrors from "../components/hooks/useAuthErrors";
import AuthLayout from "../components/layouts/AuthLayout";
import UserPlus from "../components/icons/UserPlus";
import PropTypes from "prop-types";
import cookie from "js-cookie";
import { DEMO_EMAIL } from "../constants/demo";
import StoreProvider from "../state/StoreProvider";
import {
  getAuth,
  signInWithRedirect,
  GoogleAuthProvider,
  getRedirectResult,
} from "firebase/auth";
import Head from "next/head";

function nextTitle(url) {
  if (!url || url === "/") {
    return "Orchid | Login page";
  }
  if (
    url.includes("/embryo-reports/grsdemo") ||
    url.includes("/embryo-reports/30xdemo") ||
    url.includes("/embryo-reports/5xdemo") ||
    url.includes("/demo/embryo-reports")
  ) {
    return "Orchid Demo Whole Genome Report";
  }
  if (url.includes("/embryo-reports")) {
    return "Orchid | Embryo Report";
  }
  if (url.includes("/individual-reports")) {
    return "Orchid | Individual Report";
  }
  if (url.includes("/couple-reports")) {
    return "Orchid | Couple Report";
  }
  return "Orchid | Login page";
}

const NextMeta = ({ nextUrl }) => {
  return (
    <Head>
      <title>{nextTitle(nextUrl)}</title>
    </Head>
  );
};

NextMeta.propTypes = {
  nextUrl: PropTypes.string.isRequired,
};

export const ADMIN_EMAIL_ERROR =
  "Orchid employees must use 'Sign in with Google' to access portal";
const SignIn = ({ email, nextUrl }) => {
  const auth = useAuth();
  const router = useRouter();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState();
  const { handleGoogleError, reactHookForm } = useAuthErrors({
    defaultValues: {
      email,
    },
  });

  const signIn = async ({ email, password }) => {
    setEmailError(null);
    if (isLoading) return false;
    if (email.includes("orchidhealth.com") && !email.includes("+")) {
      setEmailError(ADMIN_EMAIL_ERROR);
      return false;
    }

    setIsLoading(true);
    if (email === DEMO_EMAIL) {
      try {
        const data = await postUnauthenticatedRoute("demo/login", null, {
          email,
          password,
        });
        cookie.set("orchidAuth", data.token, { expires: 1 });
        await router.replace(
          nextUrl && nextUrl !== "/" ? nextUrl : "/embryo-reports/grsdemo"
        );
      } catch (error) {
        handleGoogleError({
          message: error.response.data.message,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        await auth.signin(email, password);
        await router?.replace(
          nextUrl && nextUrl.includes("/admin") ? "/" : nextUrl
        );
      } catch (error) {
        setIsLoading(false);
        handleGoogleError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const signInGoogle = (redirect) => {
    if (isLoading) return false;
    setIsLoading(true);
    auth
      .signinWithGoogle()
      .then(() => {
        router?.replace(redirect);
      })
      .catch(async (error) => {
        const auth = getAuth();
        await signInWithRedirect(auth, new GoogleAuthProvider());
        setIsLoading(false);
        handleGoogleError(error);
      });
  };

  useEffect(() => {
    if (!auth || !router) {
      return;
    }
    setIsLoading(true);
    const a = getAuth();
    getRedirectResult(a)
      .then((result) => (result ? auth.setUserAndCookie(result) : null))
      .then((flag) => (flag ? router?.replace(nextUrl) : null))
      .finally(() => setIsLoading(false));
  }, [auth, nextUrl, router]);

  return (
    <>
      <NextMeta nextUrl={nextUrl} />
      <AuthLayout
        backgroundImageUrl="/images/login.png"
        footerContent={
          <>
            <Text color="greyscale7" mb={2}>
              Don{"'"}t have an account yet?
            </Text>
            <Link href="/signup" mb={2}>
              <Flex direction="row">
                <UserPlus color={theme.colors.orchidBlue} height={20} />
                <Text color="orchidBlue" ml={2}>
                  Create an account
                </Text>
              </Flex>
            </Link>
          </>
        }
      >
        <Auth
          headingText="Login"
          helpContent={
            <Text color="greyscale7" fontSize="18px" textDecoration="underline">
              <Link href="/reset-password">Reset your password</Link>
            </Text>
          }
          buttonContent="Sign In"
          onSubmit={signIn}
          onGoogleSignIn={
            !router.query.demo ? () => signInGoogle(nextUrl) : null
          }
          isLoading={isLoading}
          reactHookForm={reactHookForm}
          emailError={emailError}
          email={email}
          forDemo={!!router.query.demo}
        />
      </AuthLayout>
    </>
  );
};
SignIn.propTypes = {
  email: PropTypes.string,
  nextUrl: PropTypes.string,
};

export default function Login(pageProps) {
  return (
    <StoreProvider {...pageProps}>
      <SignIn email={pageProps.email} nextUrl={pageProps.nextUrl} />
    </StoreProvider>
  );
}

export async function getServerSideProps(context) {
  let nextUrl;
  if (Array.isArray(context.query?.next)) {
    nextUrl = context.query.next.length ? context.query?.next[0] : "/";
  } else {
    nextUrl = context.query?.next || "/";
  }
  return {
    props: {
      email: context.query?.email || null,
      resetStore: true,
      nextUrl,
    },
  };
}
