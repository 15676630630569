import React, { useRef } from "react";
import {
  getDefaultState,
  initializeAccountStore,
  Provider,
} from "./accountStore";
import PropTypes from "prop-types";

const StoreProvider = ({ children, accountStore, resetStore }) => {
  const storeRef = useRef();
  if (!storeRef.current) {
    storeRef.current = initializeAccountStore(
      accountStore || getDefaultState()
    );
  } else if (resetStore) {
    storeRef.current = null;
  }

  return <Provider value={storeRef.current}>{children}</Provider>;
};

StoreProvider.propTypes = {
  children: PropTypes.node,
  accountStore: PropTypes.object,
  resetStore: PropTypes.bool,
};

export default StoreProvider;
