import React from "react";
import { string } from "prop-types";

function UserPlus({ color, ...restProps }) {
  return (
    <svg viewBox="0 0 24 24" {...restProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 12C6.47715 12 2 16.4772 2 22C2 22.2761 1.77614 22.5 1.5 22.5C1.22386 22.5 1 22.2761 1 22C1 15.9249 5.92487 11 12 11C14.817 11 17.3877 12.0595 19.3335 13.8011C19.5392 13.9852 19.5567 14.3013 19.3726 14.5071C19.1884 14.7128 18.8723 14.7304 18.6665 14.5462C16.897 12.9624 14.5615 12 12 12Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C9.51472 2 7.5 4.01472 7.5 6.5C7.5 8.98528 9.51472 11 12 11C14.4853 11 16.5 8.98528 16.5 6.5C16.5 4.01472 14.4853 2 12 2ZM6.5 6.5C6.5 3.46243 8.96243 1 12 1C15.0376 1 17.5 3.46243 17.5 6.5C17.5 9.53757 15.0376 12 12 12C8.96243 12 6.5 9.53757 6.5 6.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 19.5C23 19.7761 22.7761 20 22.5 20L18.5 20C18.2239 20 18 19.7761 18 19.5C18 19.2239 18.2239 19 18.5 19L22.5 19C22.7761 19 23 19.2239 23 19.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 17C20.7761 17 21 17.2239 21 17.5L21 21.5C21 21.7761 20.7761 22 20.5 22C20.2239 22 20 21.7761 20 21.5L20 17.5C20 17.2239 20.2239 17 20.5 17Z"
        fill={color}
      />
    </svg>
  );
}

UserPlus.propTypes = {
  color: string.isRequired,
};

export default UserPlus;
