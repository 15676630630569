import React from "react";
import PropTypes from "prop-types";
import { Button, Image } from "@chakra-ui/react";

const GoogleButton = ({ onGoogleSignIn, children, ...rest }) => {
  return (
    <Button
      height="auto"
      justifyContent="flex-start"
      padding="15px 30px"
      bg="white"
      fontWeight="normal"
      color="greyscale5"
      borderColor="greyscale2"
      borderWidth="1px"
      borderStyle="solid"
      borderRadius="2px"
      _hover={{
        backgroundColor: "white",
        borderColor: "greyscale5",
        color: "orchidGrey500",
      }}
      onClick={onGoogleSignIn}
      {...rest}
    >
      <Image src="/vendor/g-icon.png" alt="google icon" height="5" mr="3" />
      {children}
    </Button>
  );
};

GoogleButton.propTypes = {
  children: PropTypes.node.isRequired,
  onGoogleSignIn: PropTypes.func.isRequired,
};

export default GoogleButton;
