import { useForm } from "react-hook-form";

const useAuthErrors = (...reactHookFormParams) => {
  const reactHookForm = useForm(...reactHookFormParams);
  const { setError } = reactHookForm;

  const handleGoogleError = (error) => {
    // By default, all error messages are shown under the email field
    // except for this particular one
    const errorField =
      error && error.code === "auth/weak-password" ? "pass" : "email";
    setError(errorField, {
      type: "manual",
      message: error.message,
    });
  };

  return {
    handleGoogleError,
    reactHookForm,
  };
};

export default useAuthErrors;
