import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Flex,
  FormControl,
  Heading,
  Text,
  Divider,
} from "@chakra-ui/react";
import Input from "./inputs/Input";
import InputWithLabel from "./inputs/InputWithLabel";
import GoogleButton from "./buttons/GoogleButton";
import PrimaryButton from "./buttons/PrimaryButton";

const AuthForm = ({
  register,
  errors,
  onGoogleSignIn,
  buttonContent,
  helpContent,
  disclaimerContent,
  isLoading,
  prefillEmail = "",
  forDemo = false,
  ...rest
}) => {
  let emailErrorText = errors.email && errors.email.message;
  let passErrorText = errors.password && errors.password.message;

  // Transform error text to our preferred message.
  if (emailErrorText) {
    if (emailErrorText.includes("no user")) {
      emailErrorText = "Invalid email.";
    } else if (emailErrorText.includes("password")) {
      emailErrorText = "";
      passErrorText = "Invalid password.";
    }
    if (
      buttonContent.toLowerCase().includes("register") &&
      errors.email?.type !== "custom"
    ) {
      emailErrorText = "User already exists.";
    }
  }
  return (
    <Flex as="form" direction="column" {...rest}>
      <FormControl mb={3} isInvalid={emailErrorText}>
        <InputWithLabel
          label="Email:"
          aria-label="Email Address"
          id="email"
          name="email"
          type="email"
          ref={register({
            required: "Please enter your email.",
          })}
          disabled={forDemo}
          placeholder="name@site.com"
          defaultValue={prefillEmail}
          isInvalid={!!emailErrorText}
          boxProps={{ display: forDemo ? "none" : "block" }}
        />
        <Flex fontSize="12px" justifyContent="flex-end">
          <Text color="red">{emailErrorText}</Text>
        </Flex>
      </FormControl>
      <FormControl mb="6" isInvalid={passErrorText}>
        <Input
          aria-label="Password"
          name="password"
          type="password"
          placeholder="Password"
          ref={register({
            required: "Please enter a password.",
          })}
          isInvalid={!!passErrorText}
        />
        <Flex justifyContent="flex-end">
          <Text fontSize="12px" color="red">
            {passErrorText}
          </Text>
        </Flex>
      </FormControl>
      {!forDemo ? helpContent : ""}
      <PrimaryButton disabled={isLoading} mt="4" mb="auto">
        {buttonContent}
      </PrimaryButton>
      {!forDemo && (
        <>
          <Box position="relative" my="40px">
            <Divider />
            <Text
              backgroundColor="white"
              color="black"
              p="3px 25px"
              position="absolute"
              left="calc(50% - 37px)"
              top="-0.5em"
              letterSpacing="2px"
            >
              OR
            </Text>
          </Box>
          <GoogleButton disabled={isLoading} onGoogleSignIn={onGoogleSignIn}>
            {buttonContent} with Google
          </GoogleButton>
        </>
      )}
      {disclaimerContent}
    </Flex>
  );
};

AuthForm.propTypes = {
  register: PropTypes.func,
  errors: PropTypes.shape({
    email: PropTypes.shape({
      message: PropTypes.string,
      type: PropTypes.string,
    }),
    password: PropTypes.shape({
      message: PropTypes.string,
    }),
  }),
  disclaimerContent: PropTypes.node,
  helpContent: PropTypes.node,
  buttonContent: PropTypes.string,
  onGoogleSignIn: PropTypes.func,
  isLoading: PropTypes.bool,
  errorText: PropTypes.string,
  prefillEmail: PropTypes.string,
  forDemo: PropTypes.bool,
};

const Auth = ({
  disclaimerContent,
  headingText,
  helpContent,
  buttonContent,
  onSubmit,
  onGoogleSignIn,
  isLoading,
  reactHookForm,
  prefillEmail = "",
  emailError,
  forDemo = false,
  ...rest
}) => {
  const { handleSubmit, register, errors, setError } = reactHookForm;

  useEffect(() => {
    if (errors.email) {
      return;
    }
    if (emailError) {
      setError("email", { type: "custom", message: emailError });
    }
  }, [errors.email, emailError, setError]);

  return (
    <Flex direction="column" justify="center" {...rest}>
      <Heading
        as="h3"
        color="orchidBlue"
        fontSize="36px"
        fontWeight="normal"
        letterSpacing="1.5px"
        mt="60px"
        mb={forDemo ? "20px" : "60px"}
      >
        {headingText}
      </Heading>
      <AuthForm
        errors={errors}
        onSubmit={handleSubmit((data) => onSubmit(data))}
        onGoogleSignIn={onGoogleSignIn}
        register={register}
        helpContent={helpContent}
        buttonContent={buttonContent}
        disclaimerContent={disclaimerContent}
        isLoading={isLoading}
        prefillEmail={prefillEmail}
        forDemo={forDemo}
      />
    </Flex>
  );
};

Auth.propTypes = {
  disclaimerContent: PropTypes.node,
  headingText: PropTypes.string,
  helpContent: PropTypes.node,
  buttonContent: PropTypes.string,
  onSubmit: PropTypes.func,
  onGoogleSignIn: PropTypes.func,
  isLoading: PropTypes.bool,
  reactHookForm: PropTypes.object,
  prefillEmail: PropTypes.string,
  emailError: PropTypes.string,
  forDemo: PropTypes.bool,
};

export default Auth;
